<template>
  <div class="spinner-loading" v-if="Loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script setup>
  import { storeLoading } from '../stores/storeLoading';
  import { computed } from 'vue'

  const store = storeLoading();

  const Loading = computed(() => {
    return store.Loading
  })

</script>

<style scoped>
  .spinner-loading{
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 999999999999999;
}
</style>
