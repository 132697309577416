import { ref, inject } from 'vue'
import { defineStore } from 'pinia'
import axios from "axios";
import {AES, enc} from 'crypto-js';
import { useRouter } from 'vue-router'

export const storeUtenteLogin = defineStore('storeUtenteLogin', () => {
    const API_URL = inject('API_URL')
    const router = useRouter();

    var UtenteLoggato = ref(false)
    var DatiUtente = ref({})
    var DatiAccesso = ref({})
    var DatiUtenteLoggatoAggiornati = ref(false)

    function StatoUtente(){
        if(UtenteLoggato.value == false){
            if(window.sessionStorage.getItem("UtenteLoggato") == 'true' || window.localStorage.getItem("UtenteLoggato") == 'true'){
                UtenteLoggato.value = true
            }
        }
        return UtenteLoggato
    }

    function LogoutUtente(val){
        if(val == true){
            UtenteLoggato.value = false;
            DatiUtente.value = {};
            DatiAccesso.value = {}
            window.sessionStorage.removeItem("UtenteLoggato");
            window.sessionStorage.removeItem("DatiUtente");
            window.sessionStorage.removeItem("DatiAccesso");
            window.localStorage.removeItem("UtenteLoggato");
            window.localStorage.removeItem("DatiUtente");
            window.localStorage.removeItem("DatiAccesso");
            document.cookie = "memorizzaLogin= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            router.push({
                name: 'login',
            })
        }
    }

    function SalvaDatiAccesso(accessi){
        if(accessi.dati_accesso){
            if(accessi.dati_accesso.risultato == "true"){
                DatiAccesso.value = accessi.dati_accesso;
                UtenteLoggato.value = true;

                var c = document.cookie.match(/^(.*;)?\s*memorizzaLogin\s*=\s*[^;]+(.*)?$/);
                if(c){
                    window.localStorage.setItem("UtenteLoggato", 'true');
                    //window.localStorage.setItem("DatiAccesso", JSON.stringify(accessi.dati_accesso));
                    window.localStorage.setItem('DatiAccesso', AES.encrypt(JSON.stringify(accessi.dati_accesso),'NETCOMING').toString());
                }else{
                    window.sessionStorage.setItem("UtenteLoggato", 'true');
                    //window.sessionStorage.setItem("DatiAccesso", JSON.stringify(accessi.dati_accesso));
                    window.sessionStorage.setItem('DatiAccesso', AES.encrypt(JSON.stringify(accessi.dati_accesso),'NETCOMING').toString());
                }
            }
        }else{
            DatiAccesso.value = {};
            UtenteLoggato.value = false;
            window.sessionStorage.removeItem("UtenteLoggato");
            window.sessionStorage.removeItem("DatiAccesso");
            window.localStorage.removeItem("UtenteLoggato");
            window.localStorage.removeItem("DatiAccesso");
        }
    }

    function SalvaDatiUtente(utente){
        if(utente.stato == 200){

            DatiUtente.value = utente.DatiUtente;

            var c = document.cookie.match(/^(.*;)?\s*memorizzaLogin\s*=\s*[^;]+(.*)?$/);
            if(c){
                window.localStorage.setItem('DatiUtente', AES.encrypt(JSON.stringify(utente.DatiUtente),'NETCOMING').toString());
                //window.localStorage.setItem("DatiUtente", JSON.stringify(utente.DatiUtente));
            }else{
                window.sessionStorage.setItem('DatiUtente', AES.encrypt(JSON.stringify(utente.DatiUtente),'NETCOMING').toString());
                //window.sessionStorage.setItem("DatiUtente", JSON.stringify(utente.DatiUtente));
            }
        }
        else{
            DatiUtente.value = {};
            window.sessionStorage.removeItem("DatiUtente");
            window.localStorage.removeItem("DatiUtente");
        }
    }

    function AggiornaDatiUtente(id){

        var c = document.cookie.match(/^(.*;)?\s*memorizzaLogin\s*=\s*[^;]+(.*)?$/);
        if(c){
            DatiUtente.value = JSON.parse(AES.decrypt(window.localStorage.getItem('DatiUtente'), 'NETCOMING').toString(enc.Utf8));
            //DatiUtente.value = JSON.parse(window.localStorage.getItem('DatiUtente'));
        }else{
            DatiUtente.value = JSON.parse(AES.decrypt(window.sessionStorage.getItem('DatiUtente'), 'NETCOMING').toString(enc.Utf8));
            //DatiUtente.value = JSON.parse(window.sessionStorage.getItem('DatiUtente'));
        }

        if(DatiUtente.value.id == id){
            try{
                axios.get(API_URL + '/api/v1/get-user-login')
                .then(({data})=>{
                    SalvaDatiUtente(data);
                    DatiUtenteLoggatoAggiornati.value = true;
                })
            }catch(error){
                alert(error);
            }
        }

    }

    function GetDatiUtente(){
        if(window.sessionStorage.getItem("UtenteLoggato") == 'true' || window.localStorage.getItem("UtenteLoggato") == 'true'){
            var c = document.cookie.match(/^(.*;)?\s*memorizzaLogin\s*=\s*[^;]+(.*)?$/);

            if(c){
                //DatiAccesso = JSON.parse(window.localStorage.getItem('DatiAccesso'));
                //DatiUtente = JSON.parse(window.localStorage.getItem('DatiUtente'));
                DatiAccesso = JSON.parse(AES.decrypt(window.localStorage.getItem('DatiAccesso'), 'NETCOMING').toString(enc.Utf8));
                DatiUtente = JSON.parse(AES.decrypt(window.localStorage.getItem('DatiUtente'), 'NETCOMING').toString(enc.Utf8));
            }else{
                //DatiAccesso = JSON.parse(window.sessionStorage.getItem('DatiAccesso'));
                //DatiUtente = JSON.parse(window.sessionStorage.getItem('DatiUtente'));
                DatiAccesso = JSON.parse(AES.decrypt(window.sessionStorage.getItem('DatiAccesso'), 'NETCOMING').toString(enc.Utf8));
                DatiUtente = JSON.parse(AES.decrypt(window.sessionStorage.getItem('DatiUtente'), 'NETCOMING').toString(enc.Utf8));

            }
        }else{
            UtenteLoggato.value = false;
            DatiUtente.value = {};
            DatiAccesso.value = {}
            window.sessionStorage.removeItem("UtenteLoggato");
            window.sessionStorage.removeItem("DatiUtente");
            window.sessionStorage.removeItem("DatiAccesso");
            window.localStorage.removeItem("UtenteLoggato");
            window.localStorage.removeItem("DatiUtente");
            window.localStorage.removeItem("DatiAccesso");
            document.cookie = "memorizzaLogin= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

            //window.location.href = "/login";
        }

        var DatiLogin = {};
        DatiLogin['DatiAccesso'] = DatiAccesso;
        DatiLogin['DatiUtente'] = DatiUtente;
        return DatiLogin
    }


  return {
    UtenteLoggato,
    DatiUtenteLoggatoAggiornati,
    StatoUtente,
    LogoutUtente,
    SalvaDatiAccesso,
    SalvaDatiUtente,
    AggiornaDatiUtente,
    GetDatiUtente
    }
})
