import { ref} from 'vue'
import { defineStore } from 'pinia'

export const storeLoading = defineStore('storeLoading', () => {

    var Loading = ref(false);
    var Reload = ref(false)

    function SalvaStatoLoading(stato){
        if(stato == true){
            Loading.value = true;
        }else{
            Loading.value = false;
        }
    }

    function SalvaStatoReload(reload){
        if(reload == true){
            Reload.value = true;
        }else{
            Reload.value = false;
        }
    }

  return {
    Loading,
    Reload,
    SalvaStatoLoading,
    SalvaStatoReload
    }
})
