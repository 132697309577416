<script setup>
  import { RouterView } from 'vue-router'
  import LoadingComponent from '@/components/LoadingComponent.vue';
  import MetaTag from '@/components/MetaTag';
  MetaTag()
</script>

<template>
  <metainfo></metainfo>
  <RouterView />
  <LoadingComponent></LoadingComponent>
</template>

<style scoped>
    @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
</style>

