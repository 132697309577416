
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/visual-admin.css";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager } from "vue-meta";
import { createPinia } from 'pinia'
import './service/HTTP.js';

const app = createApp(App);

app.provide('API_URL', import.meta.env.VITE_APP_API_BASE_URL)
app.provide('NOME_APP', import.meta.env.VITE_APP_NOME_APP)

app.use(createPinia())
app.use(router)
app.use(createMetaManager())
app.mount('#app');


import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;


