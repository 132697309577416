import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { storeUtenteLogin } from '../stores/storeUtenteLogin';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/Pagina404.vue'),
  },
  {
    path: '/',
    name: 'home',
    redirect: '/login'
    /* component: () => import('../views/PaginaHome.vue'),
    meta:{
      NascondiSeLoggato:true
    } */
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/PaginaLogin.vue'),
    meta:{
      NascondiSeLoggato:true
    }
  },
  {
    path: '/net-admin',
    name: 'net-admin',
    meta:{
      ConAuth:true
    },
    children: [
      {
        path: '/net-admin/dashboard',
        name: 'dashboard',
        component: () => import('../views/net-admin/Dashboard.vue'),
        meta:{
          titolo: 'Dashboard',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Dashboard', link:''}
          ]
        }
      },
      {
        path: '/net-admin/profilo-utente/modifica/:id',
        name: 'profilo-utente-modifica',
        component: () => import('../views/net-admin/profilo-utente/Modifica.vue'),
        meta:{
          titolo: 'Modifica Profilo Utente',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Modifica Profilo Utente', link:'/'}
          ],
        }
      },
      {
        path: '/net-admin/utenti/index',
        name: 'utenti-index',
        component: () => import('../views/net-admin/utenti/Index.vue'),
        meta:{
          titolo: 'Utenti',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Utenti', link:'/'}
          ],
          PermessiUtente: ['gestione-utenti']
        }
      },
      {
        path: '/net-admin/utenti/modifica/:id',
        name: 'utenti-modifica',
        component: () => import('../views/net-admin/utenti/Modifica.vue'),
        meta:{
          titolo: 'Modifica Utente',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Utenti', link:'/net-admin/utenti/index'},
            {nome:'Modifica Utente', link:'/'}
          ],
          PermessiUtente: ['gestione-utenti']
        }
      },
      {
        path: '/net-admin/utenti/inserisci',
        name: 'utenti-inserisci',
        component: () => import('../views/net-admin/utenti/Inserisci.vue'),
        meta:{
          titolo: 'Inserisci Utente',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Utenti', link:'/net-admin/utenti/index'},
            {nome:'Inserisci Utente', link:'/'}
          ],
          PermessiUtente: ['gestione-utenti']
        }
      },
      {
        path: '/net-admin/permessi/inserisci',
        name: 'permessi-inserisci',
        component: () => import('../views/net-admin/permessi/Inserisci.vue'),
        meta:{
          titolo: 'Inserisci Permesso Utente',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Utenti', link:'/net-admin/permessi/index'},
            {nome:'Inserisci Permesso Utente', link:'/'}
          ],
          PermessiUtente: ['gestione-permessi']
        }
      },
      {
        path: '/net-admin/permessi/index',
        name: 'permessi-index',
        component: () => import('../views/net-admin/permessi/Index.vue'),
        meta:{
          titolo: 'Permessi Utente',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Permessi', link:'/'},
          ],
          PermessiUtente: ['gestione-permessi']
        }
      },
      {
        path: '/net-admin/permessi/modifica/:id',
        name: 'permessi-modifica',
        component: () => import('../views/net-admin/permessi/Modifica.vue'),
        meta:{
          titolo: 'Modifica Permessi',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Permessi', link:'/net-admin/permessi/index'},
            {nome:'Modifica Permessi', link:'/'}
          ],
          PermessiUtente: ['gestione-permessi']
        }
      },
      {
        path: '/net-admin/ruoli/inserisci',
        name: 'ruoli-inserisci',
        component: () => import('../views/net-admin/ruoli/Inserisci.vue'),
        meta:{
          titolo: 'Inserisci Ruolo Utente',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Utenti', link:'/net-admin/ruoli/index'},
            {nome:'Inserisci Ruolo Utente', link:'/'}
          ],
          PermessiUtente: ['gestione-ruoli']
        }
      },
      {
        path: '/net-admin/ruoli/index',
        name: 'ruoli-index',
        component: () => import('../views/net-admin/ruoli/Index.vue'),
        meta:{
          titolo: 'Ruoli Utente',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Ruoli', link:'/'},
          ],
          PermessiUtente: ['gestione-ruoli']
        }
      },
      {
        path: '/net-admin/ruoli/modifica/:id',
        name: 'ruoli-modifica',
        component: () => import('../views/net-admin/ruoli/Modifica.vue'),
        meta:{
          titolo: 'Modifica Ruoli',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Ruoli', link:'/net-admin/ruoli/index'},
            {nome:'Modifica Ruoli', link:'/'}
          ],
          PermessiUtente: ['gestione-ruoli']
        }
      },
      {
        path: '/net-admin/ricette/index',
        name: 'ricette-index',
        component: () => import('../views/net-admin/ricette/Index.vue'),
        meta:{
          titolo: 'Ricette',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Ricette', link:'/'}
          ]
        }
      },
      {
        path: '/net-admin/ricette/modifica/:id',
        name: 'ricette-modifica',
        component: () => import('../views/net-admin/ricette/Modifica.vue'),
        meta:{
          titolo: 'Modifica Ricetta',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Ricette', link:'/net-admin/ricette/index'},
            {nome:'Modifica Ricetta', link:'/'}
          ]
        }
      },
      {
        path: '/net-admin/ricette/inserisci',
        name: 'ricette-inserisci',
        component: () => import('../views/net-admin/ricette/Inserisci.vue'),
        meta:{
          titolo: 'Inserisci Ricetta',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Ricette', link:'/net-admin/ricette/index'},
            {nome:'Inserisci Ricetta', link:'/'}
          ],
        }
      },
      {
        path: '/net-admin/ricette/index-all',
        name: 'ricette-index-all',
        component: () => import('../views/net-admin/ricette/Index-all.vue'),
        meta:{
          titolo: 'Tutte le Ricette',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Tutte le Ricette', link:'/'}
          ]
        }
      },
      {
        path: '/net-admin/ricette/genera',
        name: 'genera-ricetta',
        component: () => import('../views/net-admin/genera/Index.vue'),
        meta:{
          titolo: 'Genera Ricetta',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Genera', link:'/'},
          ],
        }
      },
      {
        path: '/net-admin/lasciati-ispirare/index',
        name: 'lasciati-ispirare-index',
        component: () => import('../views/net-admin/lasciati-ispirare/Index.vue'),
        meta:{
          titolo: 'Lasciati Ispirare',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Lasciati ispirare', link:'/'},
          ],
        }
      },
      {
        path: '/net-admin/lasciati-ispirare/visualizza/:id',
        name: 'lasciati-ispirare-visualizza',
        component: () => import('../views/net-admin/lasciati-ispirare/Visualizza.vue'),
        meta:{
          titolo: 'Visualizza ispirazione',
          percorso: [
            {nome:'Home', link:'/net-admin/dashboard'},
            {nome:'Lasciati ispirare', link:'/net-admin/lasciati-ispirare/index'},
            {nome:'Visualizza ispirazione', link:'/'},
          ],
        }
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  //history: createWebHistory(),
  routes
});



router.beforeEach(
  (to, from, next) => {

    const DatiLogin = storeUtenteLogin().GetDatiUtente();
    const statoLogin = storeUtenteLogin().StatoUtente().value

    if(to.meta.ConAuth && statoLogin == false){
      next("/login");
    }
    else if(statoLogin == true && to.meta.NascondiSeLoggato){
      next({ name: 'dashboard' })
    }
    else if(to.meta.PermessiUtente){
      let AccessoAutorizzato = to.meta.PermessiUtente.some(value=> DatiLogin.DatiUtente.permessi.indexOf(value) !== -1)
      if(AccessoAutorizzato == true){
        next()
      }
    }
    else{
      next()
    }
  }
)


export default router
