import axios from "axios";
import {AES, enc}from 'crypto-js';
import { inject } from 'vue'
import { storeUtenteLogin } from "@/stores/storeUtenteLogin";
import { storeLoading } from "@/stores/storeLoading";


const API_URL = inject('API_URL');

axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, PUT, DELETE';
axios.interceptors.request.use(async function (config) {

    const store = storeUtenteLogin()
    const StatoUtente = store.StatoUtente()

    //GET UTENTE LOGIN
    if(window.sessionStorage.getItem("UtenteLoggato") == 'true' || window.localStorage.getItem("UtenteLoggato") == 'true' && StatoUtente){
        var c = document.cookie.match(/^(.*;)?\s*memorizzaLogin\s*=\s*[^;]+(.*)?$/);
        var user;
        if(c){
            //user = JSON.parse(window.localStorage.getItem('DatiAccesso'));
            user = JSON.parse(AES.decrypt(window.localStorage.getItem('DatiAccesso'), 'NETCOMING').toString(enc.Utf8));
        }else{
            //user = JSON.parse(window.sessionStorage.getItem('DatiAccesso'))
            user = JSON.parse(AES.decrypt(window.sessionStorage.getItem('DatiAccesso'), 'NETCOMING').toString(enc.Utf8));
        }
    }
    //GET TOKEN
    if(user){
        config.headers.Authorization =  'Bearer ' + user.access_token;
    }

    return config;

}, (error) => {

    return Promise.reject(error);
  });



axios.interceptors.response.use((response) => {

    return response;
  }, (error) => {

    //SE L'UTENTE NON è AUTENTICATO DURANTE LE CHIAMATE AXIOS EFFETTA IL LOGOUT
    if(error.response.status == 401 && error.response.statusText == "Unauthorized"){
        storeUtenteLogin().LogoutUtente(true);
        storeLoading().SalvaStatoLoading(false)
    }
    return Promise.reject(error);
  });
